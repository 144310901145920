import { Ref } from "vue";

export const startTimer = (timer: Ref<number>) => {
  let timerInterval = 0;

  if (timer.value > 0) {
    timerInterval = window.setInterval(() => {
      timer.value--;
      if (timer.value <= 0) {
        clearInterval(timerInterval);
      }
    }, 1000);
  }
};
