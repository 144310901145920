import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { startTimer } from "@/composables/startTimer";
import { RouteName } from "@/enums/RouteName";

export default () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const timer = ref<number>(0);
  const endTime = ref<number>(0);

  onBeforeMount(() => {
    if (route.hash === "#resend-email") {
      setResetPasswordTimer();
    }
  });

  const onResetPasswordRequested = (timeout: number) => {
    endTime.value = timeout * 1000 + new Date().getTime();
    updateRoute();
    store.dispatch("user/setEmailTimeout", `${endTime.value}`);
    timer.value = Math.round((endTime.value - new Date().getTime()) / 1000);
    if (timer.value >= 1) {
      startTimer(timer);
    } else {
      timer.value = 0;
    }
  };

  const formattedTimer = computed(() => {
    if (timer.value < 0) {
      return 0;
    }
    const minutes = Math.floor(timer.value / 60);
    const seconds = +(timer.value % 60).toFixed(0);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  });

  const setResetPasswordTimer = () => {
    if (route.hash !== "#resend-email") {
      updateRoute();
    }
    const emailTimeout = store.getters["user/getForgotPasswordEmailTimeout"];
    if (emailTimeout) {
      timer.value = Math.round(
        (Number(emailTimeout) - new Date().getTime()) / 1000
      );
      if (timer.value >= 1) {
        startTimer(timer);
      } else {
        timer.value = 0;
      }
    }
  };

  const updateRoute = () => {
    const { path, params, query } = route;
    router.replace({ path, params, query, hash: "#resend-email" });
  };

  const handleResetPasswordRequestError = (error: string | undefined) => {
    if (error === "TooManyRequestsException") {
      setResetPasswordTimer();
    }
  };

  return {
    onResetPasswordRequested,
    handleResetPasswordRequestError,
    formattedTimer,
    timer,
  };
};
